import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import './Contact.css'; // Include the custom CSS for styling

function Contact() {
  return (
    <div className="contact">
      <Helmet>
        <title>Contact Us | Brothers Moving Co. | Buffalo, NY</title>
        <meta
          name="description"
          content="Get in touch with Brothers Moving Co., a trusted moving company in Buffalo, NY. Reach out for quotes or inquiries about our services."
        />
        <meta
          name="keywords"
          content="Contact Brothers Moving Co., Buffalo moving company, moving services Buffalo NY, moving quote Buffalo"
        />
        <meta name="author" content="Brothers Moving Co." />

        {/* Structured Data (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ContactPage",
            "name": "Contact Us | Brothers Moving Co.",
            "url": "https://www.brothersmovingco.com/contact",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+1-716-260-9249",
              "contactType": "customer service",
              "areaServed": "US",
              "availableLanguage": "English"
            },
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Allen St",
              "addressLocality": "Buffalo",
              "addressRegion": "NY",
              "postalCode": "14201",
              "addressCountry": "US"
            }
          })}
        </script>

        {/* Social Media Meta Tags */}
        <meta property="og:title" content="Contact Us | Brothers Moving Co." />
        <meta property="og:description" content="Contact Brothers Moving Co. for your moving needs in Buffalo, NY. Request a quote or reach out for any questions." />
        <meta property="og:image" content="/tab.jpg" /> {/* Assuming the logo is in the public folder */}
        <meta property="og:url" content="https://www.brothersmovingco.com/contact" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="container">
        {/* Page Title */}
        <section className="contact-page-title text-center py-5">
          <h1>Contact Us</h1>
        </section>

        {/* Contact Form Section */}
        <section className="contact-form-section py-5">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6">
              <form className="contact-form">
                <div className="form-group">
                  <label htmlFor="name">Full Name</label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder="Enter your full name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Enter your email address"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="tel"
                    id="phone"
                    className="form-control"
                    placeholder="Enter your phone number"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    id="message"
                    className="form-control"
                    rows="5"
                    placeholder="Tell us how we can help you"
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </section>

        {/* Additional Contact Info Section */}
        <section className="contact-info-section text-center py-5">
          <h3>Contact Information</h3>
          <p>
            <strong>Email:</strong> info@brothersmovingco.com
          </p>
          <p>
            <strong>Phone:</strong> (716)-260-9249
          </p>
          <p>
            <strong>Location:</strong> Buffalo, NY
          </p>
        </section>

      
      </div>
    </div>
  );
}

export default Contact;

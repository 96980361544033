import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import { Link } from 'react-router-dom'; // Import Link for navigation
import './About.css'; // Include the custom CSS for styling

function About() {
  return (
    <div className="about">
      {/* Helmet for SEO */}
      <Helmet>
        <title>About Us | Brothers Moving Co. | Movers in Buffalo, NY</title>
        <meta
          name="description"
          content="Learn more about Brothers Moving Co., a family-owned moving company in Buffalo, NY. We specialize in stress-free residential and commercial moves with honesty and care."
        />
        <meta
          name="keywords"
          content="Buffalo movers, moving company in Buffalo NY, residential movers Buffalo, commercial movers Buffalo, family-owned moving company Buffalo"
        />
        <meta name="author" content="Brothers Moving Co." />

        {/* Structured Data (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MovingCompany",
            "name": "Brothers Moving Co.",
            "image": "public/tab.jpg", // Replace with actual image URL
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Allen St", // Replace with actual address
              "addressLocality": "Buffalo",
              "addressRegion": "NY",
              "postalCode": "14201", // Replace with actual postal code
              "addressCountry": "US"
            },
            "telephone": "+1-716-260-9249", // Replace with actual phone number
            "url": "https://www.brothersmovingco.com",
            "description": "A family-owned moving company specializing in residential and commercial moves in Buffalo, NY.",
            "sameAs": [
              "Your social media URLs (if applicable)" // Replace with actual social media links
            ]
          })}
        </script>

        {/* Social Media Meta Tags */}
        <meta property="og:title" content="About Us | Brothers Moving Co." />
        <meta property="og:description" content="Family-owned moving company in Buffalo, NY, offering residential and commercial moving services you can trust." />
        <meta property="og:image" content="URL_to_your_logo_or_image" /> {/* Replace with your image URL */}
        <meta property="og:url" content="https://www.brothersmovingco.com/about" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {/* About Us Header Section */}
      <div className="about-header text-center py-5">
        <h1>About Brothers Moving Co.</h1>
        <p className="tagline">"When we work with you, we bring you in like family and treat you like family."</p>
      </div>

      {/* Who We Are Section */}
      <section className="who-we-are py-5">
        <div className="container">
          <div className="row">
            <article className="col-md-6">
              <h2>Who We Are</h2>
              <p>
                At Brothers Moving Co., we’re more than just movers—we’re a family helping families. We take the stress out of moving with a service built on trust, care, and a genuine commitment to our customers.
              </p>
              <p>
                Our philosophy is simple: <strong>treat every customer like family and every move like it matters</strong>. No shortcuts, no hidden fees—just honest, reliable service. Let us handle the heavy lifting so you can focus on starting the next chapter.
              </p>
            </article>
            <div className="col-md-6">
              <img
                src={`tab.jpg`} // Replace with actual image path
                alt="Brothers Moving Co. Buffalo, NY"
                className="img-fluid rounded"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Our Mission Section */}
      <section className="our-mission py-5 bg-light">
        <div className="container text-center">
          <h2>Our Mission</h2>
          <p>
            Our mission is to make moving stress-free and seamless. We provide care and professionalism that you won’t find anywhere else. Trust us with your belongings, and we’ll exceed your expectations at every step.
          </p>
          <p>
            Honesty and transparency are at the heart of everything we do. From upfront pricing with no hidden fees to open communication throughout the process, we build trust with every customer. Your move deserves the care and integrity we bring to every job.
          </p>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-us py-5">
        <div className="container">
          <h2 className="text-center">Why Choose Brothers Moving Co.?</h2>
          <div className="row mt-4">
            <article className="col-md-4">
              <div className="choose-card p-4 border">
                <h3>Family-Owned and Operated</h3>
                <p>Built on family values, we bring trust and care to every move we handle.</p>
              </div>
            </article>
            <article className="col-md-4">
              <div className="choose-card p-4 border">
                <h3>Honesty and Transparency</h3>
                <p>From upfront pricing to the utmost care for your belongings, we’re committed to integrity.</p>
              </div>
            </article>
            <article className="col-md-4">
              <div className="choose-card p-4 border">
                <h3>Individual Care and Attention</h3>
                <p>Every move is unique, and we customize our approach to meet your needs.</p>
              </div>
            </article>
          </div>
        </div>
      </section>

      <section className="cta-section text-center py-5">
        <p className="cta-text">
          Check out our <Link to="/services" className="cta-link">services</Link> or <Link to="/contact" className="cta-link">contact us</Link> to learn more about how we can help you with your next move!
        </p>
      </section>


    </div>
  );
}

export default About;

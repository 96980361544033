import React from 'react';
import './Services.css'; // Include the updated CSS
import { Link } from 'react-router-dom'; // Import Link for routing

function Services() {
  return (
    <div className="services">
      <div className="container">

        {/* Custom Services Header */}
        <section className="custom-services-list">
          <h2 className="custom-services-header-title">Your Trusted Partner for Stress-Free Moves</h2>
          <p className="custom-services-header-subtitle">
            At Brothers Moving Co., we pride ourselves on offering seamless, top-quality moving services that are fast, reliable, and hassle-free. Whether you're moving your home, relocating your business, or need efficient junk removal, we ensure every detail is taken care of with the utmost professionalism, care, and transparency. Trust us to handle your move as if it were our own.
          </p>
        </section>

        {/* Services Section */}
        <section className="services-list">
          <div className="row text-center">
            {/* Residential Moves */}
            <div className="col-md-4">
              <div className="service-card">
                <h3>Residential Moves</h3>
                <p>
                  Moving your home doesn’t have to be stressful. We treat your home and belongings with the utmost care, ensuring everything arrives safely and on time, just like it was our own.
                </p>
              </div>
            </div>
            {/* Commercial Moves */}
            <div className="col-md-4">
              <div className="service-card">
                <h3>Commercial Moves</h3>
                <p>
                  Whether relocating your office or entire business, we specialize in seamless commercial moves that minimize downtime and disruption to keep your business running smoothly.
                </p>
              </div>
            </div>
            {/* Junk Removal */}
            <div className="col-md-4">
              <div className="service-card">
                <h3>Junk Removal</h3>
                <p>
                  Need to clear out clutter? Our junk removal services are fast, efficient, and eco-friendly, so you can quickly get rid of unwanted items without any hassle.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Call-to-Action Section */}
        <section className="services-cta text-center py-5">
          <h3>Why Choose Brothers Moving Co.?</h3>
          <p>
            Whether you're moving locally, long-distance, or need junk removal, we provide a seamless, stress-free experience. Our commitment to honesty, efficiency, and professionalism sets us apart.
          </p>
          <Link to="/contact" className="btn btn-primary">Contact Us</Link>
        </section>
      </div>
    </div>
  );
}

export default Services;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './Home.css'; // Custom CSS for this component

function Home() {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleZoom = () => {
      const zoomLevel = window.outerWidth / window.innerWidth;
      setIsMobileView(zoomLevel > 1.5 || window.innerWidth <= 768); // Adjust threshold for zoom detection
    };

    window.addEventListener('resize', handleZoom);
    handleZoom(); // Check on initial load

    return () => {
      window.removeEventListener('resize', handleZoom);
    };
  }, []);

  return (
    <div className={`home ${isMobileView ? 'mobile-friendly' : ''}`}>
      {/* Main Banner */}
      <div className="main-banner">
        <div className="container text-center text-white">
          <h2>WNY's Premier Moving Services</h2>
          <p>Efficient and Reliable Moving Services Across Erie County</p>
          <Link to="/contact" className="btn-primary">Get a Quote</Link>
        </div>
      </div>

      {/* Areas We Service Section */}
      <section className="areas-we-service py-5">
        <div className="container">
          <div className="row">

            {/* Right Column: Text Content */}
            <div className="col-md-6">
              <h3>Areas We Service</h3>
              <p>We proudly offer moving services to the following areas in and around Buffalo, NY:</p>
              <ul>
                <li>Buffalo</li>
                <li>Amherst</li>
                <li>Williamsville</li>
                <li>Tonawanda</li>
                <li>Clarence</li>
                <li>Cheektowaga</li>
                <li>Depew</li>
                <li>Niagara Falls</li>
                <li>Lockport</li>
                <li>Hamburg</li>
                <li>Orchard Park</li>
                <li>Erie County & Its Neighboring Regions</li>
              </ul>
            </div>

            {/* Left Column: Map */}
            <div className="col-md-6">
              <div className="map-container">
                {/* Embed Google Map iframe */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d314782.33820581774!2d-78.82053478979314!3d42.861399696192315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1736010657787!5m2!1sen!2sus"
                  width="100%"
                  height="350"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  title="Google Map of Buffalo"
                />
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="services-section py-5 bg-light">
        <div className="container text-center">
          <h3>Our Services</h3>
          <div className="row">
            <div className="col-md-4">
              <div className="service-card p-4 mb-3">
                <h5>Residential Moving</h5>
                <p>We specialize in residential moving, ensuring that your home relocation is safe and on-time.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service-card p-4 mb-3">
                <h5>Commercial Moving</h5>
                <p>Relocating your business? We provide commercial moving services for offices and businesses.</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="service-card p-4 mb-3">
                <h5>Junk Removal</h5>
                <p>Need to clear out your space? Our junk removal service helps you get rid of unwanted items quickly.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="contact-section py-5 bg-light">
        <div className="container text-center">
          <h3>Contact Us</h3>
          <p>Ready to move? Get in touch with us today and let's get started!</p>
          <Link to="/contact" className="btn-primary">Contact Us</Link>
        </div>
      </section>
    </div>
  );
}

export default Home;
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import react-helmet for SEO
import './App.css'; // Import the custom CSS

// Import components for different pages
import Logo from './img/LogoTrestrue.png';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Router>
      <div className="App">
        {/* Global Helmet for website-wide metadata */}
        <Helmet>
          <title>Brothers Moving Co. | Movers in Buffalo, NY</title>
          <meta 
            name="description" 
            content="Looking for reliable movers in Buffalo, NY? Brothers Moving Co. offers stress-free residential, commercial, and junk removal services. Call us today!" 
          />
          <meta 
            name="keywords" 
            content="movers in Buffalo, Buffalo moving company, residential movers Buffalo, commercial movers Buffalo, junk removal Buffalo NY" 
          />
          <meta name="author" content="Brothers Moving Co." />
        </Helmet>

        <header>
          <nav fixed="top" bg="dark" variant="dark">
            <div className="navbar-container">
              {/* Logo */}
              <div className="logo-container">
                <a href="/">
                  <img
                    src={Logo}
                    alt="Brother's Moving Logo - Trusted Movers in Buffalo"
                    className="img-fluid logo"
                  />
                </a>
              </div>

              {/* Menu Toggle Button */}
              <button className="menu-toggle" onClick={toggleMenu}>
                {menuOpen ? '⨯' : '☰'}
              </button>

              {/* Navigation Menu */}
              <ul className={`nav-menu ${menuOpen ? 'open' : ''}`}>
                <li>
                  <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
                </li>
                <li>
                  <Link to="/about" onClick={() => setMenuOpen(false)}>About Us</Link>
                </li>
                <li>
                  <Link to="/services" onClick={() => setMenuOpen(false)}>Services</Link>
                </li>
                <li>
                  <Link to="/contact" onClick={() => setMenuOpen(false)}>Contact Us</Link>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>

        {/* Footer with NAP information */}
        <footer> 
          <p>&copy; 2025 Brothers Moving Co.  |   Buffalo, NY  |   <a href="tel:7162609249">(716)-260-9249</a></p>
          <p>Phone: <a href="tel:7162609249">(716)-260-9249</a></p>
          <p>Email: <a href="mailto:info@brothersmovingco.com">info@brothersmovingco.com</a></p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
